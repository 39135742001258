<template>
  <b-table
    borderless
    striped
    responsive
    hover
    v-if="tableItems && tableFields"
    :thead-class="tableHeader"
    :fields="tableFields"
    :items="tableItems"
    @row-clicked="
      (item, index, event) => $emit('rowClicked', item, index, event)
    "
    class="table"
  >
    <template #cell(index)="data">
      {{ data.index + 1 }}
    </template>
    <template #head()="data">
      <span>{{ data.label }}</span>
    </template>
    <template #cell(name)="row">
      <p class="text-left">
        <b v-if="hasUser(row.item)">
          {{ getUserDisplayName(row.item) }}
        </b>
        <b v-else>
          {{ getGroupName(row.item) }}
        </b>
      </p>
    </template>
    <template #cell(subject)="row">
      <p class="text-left">
        <b>
          {{ getGroupSubject(row.item) }}
        </b>
      </p>
    </template>
    <template #cell(courseImg)="row">
      <div class="thumbnail m-auto">
        <img :src="`${getCourseThumbnail(row.item)}`" />
      </div>
    </template>
    <template #cell(course)="row">
      <p class="text-left">
        <b>{{ getCourseName(row.item) }}</b>
      </p>
    </template>
    <template #cell(student_name)="row">
      <router-link
        v-if="isActive(row.item) && !isDeleted(row.item) && hasUser(row.item)"
        :class="'student-name'"
        class="text-left d-block"
        :to="`${$route.params.id}/${row.item.id}`"
      >
        <b>
          {{ getUserDisplayName(row.item) }}
        </b>
      </router-link>
      <b v-else-if="row.item">
        {{ getUserDisplayName(row.item) }}
      </b>
    </template>
    <template #cell(email)="row">
      <p class="text-left">
        {{ getUserDisplayName(row.item, { email: true }) }}
      </p>
    </template>
    <template #cell(watch_member)="row">
      <router-link
        v-if="isActive(row.item) && isInvited(row.item)"
        :to="`${$route.params.id}/${row.item.id}`"
      >
        <b-button variant="outline-primary">Pozrieť</b-button>
      </router-link>
    </template>
    <template #cell(deadline)="row">
      <p
        v-if="row.item.attributes.deadline"
        :class="!isActive(row.item) && isAdmin && 'white'"
      >
        {{ formatDate(row.item.attributes.deadline) }}
      </p>
      <p v-else>-</p>
    </template>
    <template #cell(starts_at)="row">
      <p
        v-if="row.item.attributes.startsAt"
        :class="!isActive(row.item) && isAdmin && 'white'"
      >
        {{ formatDate(row.item.attributes.startsAt) }}
      </p>
      <p v-else>-</p>
    </template>
    <template #cell(watch_group)="row" size="sm">
      <router-link v-if="isAdmin" :to="`/triedy/${row.item.id}`"
        ><b-button type="button" variant="outline-secondary" size="sm"
          >Pozrieť</b-button
        ></router-link
      >
      <router-link v-else :to="`/triedy/${getGroupId(row.item)}`"
        ><b-button type="button" variant="outline-secondary" size="sm"
          >Pozrieť</b-button
        ></router-link
      >
    </template>
    <template #cell(members_count)="row">
      <p>{{ getMembersCount(row.item) }}</p>
    </template>
    <template #cell(watch_or_reactivate)="row" size="sm">
      <router-link
        v-if="isActive(row.item)"
        :to="`${$route.params.id}/uloha/${row.item.id}`"
      >
        <b-button type="button" variant="outline-secondary" size="sm"
          >Pozrieť</b-button
        >
      </router-link>
      <b-button
        v-else
        :class="'reactivate-btn'"
        @click="emitItemToReactivate(row.item.id)"
        >Reaktivovať</b-button
      >
    </template>
    <template #cell(go_to_course)="row" size="sm">
      <router-link
        v-if="row.item.course"
        :to="`/kurz/${getCourseSlug(row.item)}`"
      >
        <b-button type="button" variant="outline-secondary" size="sm"
          >Prejsť na úlohu</b-button
        >
      </router-link>
    </template>
    <template #cell(is_removed)="row">
      <b-button
        variant="outline-primary"
        @click="emitItemToReactivate(row.item.id)"
        class="mr-4 mt-1"
        >Reaktivovať</b-button
      >
    </template>
    <template #cell(active_tasks)="row">
      <p v-if="!isNaN(getActiveTaskCount(row.item))">
        {{ getActiveTaskCount(row.item) }}
      </p>
      <p v-if="!isNaN(getCompletedTaskCount(row.item))">
        {{ getCompletedTaskCount(row.item) }}
      </p>
    </template>
    <template #cell(created_at)="row" size="sm">
      <p v-if="getCreatedAt(row.item)">
        {{ formatDate(getCreatedAt(row.item)) }}
      </p>
    </template>
    <template #cell(exams_count)="row">
      <p v-if="getTaskPoints(row.item)">
        {{ getTaskPoints(row.item) }}
      </p>
      <p v-else :class="'red-group'">Nevypracované</p>
    </template>
    <template #cell(waiting)>
      <b>Čaká sa na schválenie...</b>
    </template>
    <template #cell(remove)="row" size="sm">
      <svg-cross-icon
        v-if="isAdmin"
        v-b-popover.hover.top="'Odstrániť'"
        @click.stop="emitItemToRemove(row.item)"
      />
      <svg-cross-icon
        v-else
        v-b-popover.hover.top="'Odísť'"
        @click.stop="emitItemToRemove(row.item)"
      />
    </template>
    <template #cell(accept)="row">
      <div class="d-flex float-right">
        <b-button
          @click="
            $emit('studentConfirmation', { id: row.item.id, confirmed: true })
          "
          :class="'accept-btn mr-2'"
          >Schváliť</b-button
        >
        <b-button
          @click="
            $emit('studentConfirmation', { id: row.item.id, confirmed: false })
          "
          :class="'deny-btn'"
          >Zamietnuť</b-button
        >
      </div>
    </template>
  </b-table>
</template>

<script>
import moment from "moment-timezone";
import { mapGetters } from "vuex";
import {
  getUserDisplayName,
  getCourseThumbnailUrl,
  getPoints,
} from "/utils/helpers";
import { get, sumBy } from "lodash";

export default {
  components: {
    "svg-cross-icon": () => import("/assets/icons/cross.svg?inline"),
    //'svg-bullet-icon': () => import('/assets/icons/bullet.svg?inline')
  },

  props: {
    tableItems: {
      required: true,
      type: Array,
    },
    tableFields: {
      required: true,
      type: Array,
    },
    tableHeader: {
      required: false,
      type: String,
      default: "",
    },
  },

  computed: {
    ...mapGetters("auth", ["user"]),
    isAdmin() {
      return this.user.type === "teacher";
    },
  },
  methods: {
    emitItemToRemove(item) {
      if (get(item, "attributes.group.data.attributes")) {
        this.$emit("groupToLeave", item);
      } else if (get(item, "attributes.user")) {
        this.$emit("studentToRemove", item);
      } else if (get(item, "attributes.name")) {
        this.$emit("groupToRemove", item);
      }
      //'group' in this.tableItems[0] ? this.$emit('groupToRemove', item): this.$emit('groupToLeave', item.group) ?  'response' in this.tableItems[0] ? this.$emit('taskToRemove', item.task.course) : this.$emit('studentToRemove', item)
    },
    emitItemToReactivate(id) {
      this.$emit("itemToReactivate", id);
    },
    getCreatedAt(item) {
      const group = get(item, "attributes.group.data");

      if (group) {
        return get(group, "attributes.createdAt");
      }

      return get(item, "createdAt") || get(item, "attributes.createdAt");
    },
    getCourseThumbnail(item) {
      const courseAttribute = get(item, "attributes.course.data");

      if (courseAttribute) {
        return getCourseThumbnailUrl(courseAttribute);
      }

      return getCourseThumbnailUrl(item);
    },
    getCourseName(item) {
      const course = get(item, "attributes.course.data") || item;
      return get(course, "attributes.name");
    },
    getCourseSlug(item) {
      const course = get(item, "attributes.course.data") || item;
      return get(course, "attributes.slug");
    },
    getTasks(item) {
      return (
        get(item, "attributes.tasks.data") ||
        (() => {
          const taskResponses = get(item, "attributes.taskResponses.data");
          if (taskResponses)
            return taskResponses.map(
              (taskResponse) => taskResponse.attributes.task.data
            );
          return undefined;
        })() ||
        get(item, "attributes.group.data.attributes.tasks.data") ||
        get(item, "attributes.course.data.attributes.tasks.data") ||
        (Array.isArray(item) ? item : [])
      );
    },
    getActiveTaskCount(item) {
      if (item.attributes.user) return undefined;
      const tasks = this.getTasks(item);
      const activeTasks = tasks
        .filter((task) => task && this.isActive(task))
        .map((task) => {
          return task.id;
        });
      return new Set(activeTasks).size;
    },
    getTaskCount(item) {
      const tasks = this.getTasks(item);
      return tasks.length;
    },
    getCompletedTaskCount(item) {
      if (!item.attributes.user) return undefined;
      const tasks = this.getTasks(item);
      return new Set(tasks.filter(Boolean).map((task) => task.id)).size;
    },
    getGroupName(item) {
      return (
        get(item, "name") ||
        get(item, "attributes.name") ||
        get(item, "attributes.group.data.attributes.name")
      );
    },
    getGroupSubject(item) {
      return (
        get(item, "subject") ||
        get(item, "attributes.subject") ||
        get(item, "attributes.group.data.attributes.subject")
      );
    },
    getGroupId(item) {
      return get(item, "attributes.group.data.id");
    },
    getMembersCount(item) {
      const members =
        get(item, "attributes.members.data") ||
        get(item, "attributes.group.data.attributes.members.data") ||
        [];

      return members.length;
    },
    hasUser(item) {
      return Boolean(get(item, "attributes.user.data"));
    },
    getUserDisplayName(item, pickFields = {}) {
      return getUserDisplayName(item, pickFields);
    },
    formatDate(date) {
      return moment(date)
        .utc()
        .tz("Europe/Bratislava")
        .format("DD.MM.YYYY HH:mm");
    },
    isDeleted(item) {
      return Boolean(get(item, "attributes.deletedAt"));
    },
    isActive(task) {
      if (!get(task, "attributes.startsAt")) {
        return true;
      }

      const currentDate = moment();
      const started = currentDate.isSameOrAfter(task.attributes.startsAt);
      const active = currentDate.isBefore(task.attributes.deadline);

      return !started || (started && active);
    },
    isInvited(item) {
      return Boolean(get(item, "invited") || get(item, "attributes.invited"));
    },
    getTaskPoints(item) {
      const [response] = get(item, "attributes.responses.data") || [];

      if (!response) {
        return null;
      }

      const course = get(item, "attributes.course");
      const { correctPoints, maxPoints } = getPoints({ response, course });

      return `${correctPoints} / ${maxPoints}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.thumbnail {
  height: 3rem;
  width: 4.75rem;
  border-radius: 0.25rem;
  overflow: hidden;

  img {
    width: 4.75rem;
    object-fit: cover;
  }
}
svg {
  cursor: pointer;
}

.table {
  &.--clickable-rows {
    tbody tr {
      cursor: pointer;
    }
  }
}

a {
  text-decoration: none;
  color: var(--a-color-blue-button);
  font-weight: lighter;
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  &:hover {
    color: white;
  }
}

::v-deep table {
  overflow-y: hidden;
  overflow-x: hidden;
  thead {
    tr {
      border-bottom: 1px solid var(--a-color-blue-border);
      th {
        text-align: center;
        span {
          color: var(--a-color-blue-button);
          font-weight: lighter;
          font-family: Poppins;
          font-style: normal;
          font-weight: 300;
          font-size: 0.875rem;
          line-height: 1.3125rem;
        }
      }
    }
  }
  tbody {
    tr {
      td {
        text-align: center;
        align-items: center;
        color: var(--a-color-blue-dark);
        vertical-align: middle;

        p {
          margin-bottom: 0;
        }

        button {
          color: var(--a-color-blue-button);
          border-color: var(--a-color-blue-button);
          font-size: small;
          font-weight: bold;
          min-width: 8.5rem;
          min-height: 2.4373rem;
          padding: 0;

          &:hover {
            background-color: var(--a-color-secondary);
            color: var(--a-color-white);
            border-color: var(--a-color-secondary);
          }
        }
        a {
          text-decoration: none;
          color: var(--a-color-blue-dark);
          font-weight: bold;
        }
      }
    }
  }
}

::v-deep .name {
  width: 9.375rem;
}

::v-deep .reactivate-btn {
  width: 8.5rem;
  background-color: var(--a-color-blue-dark);
  color: var(--a-color-white);
  border-radius: 6.25;
  border-color: var(--a-color-blue-dark);
}

::v-deep .blue-group {
  color: var(--a-color-blue-button);
}

::v-deep .red-group {
  color: #f64a4a;
}

::v-deep .white {
  p,
  b {
    color: var(--a-color-white);
  }
}

::v-deep .student-name {
  &:hover {
    color: var(--a-color-blue-dark);
  }
}

::v-deep .align-start {
  text-align: start;
}

::v-deep .low-opacity {
  opacity: 50%;
}

::v-deep .align-end {
  text-align: end;
}

::v-deep .members_count {
  width: 3.125rem;
}

::v-deep .group {
  width: 12.5rem;
  max-width: 12.5rem;
}

::v-deep .email {
  width: 16.75rem;
}

::v-deep .cancel {
  color: var(--a-color-blue-button);
  box-sizing: border-box;
  width: 3.125;
  text-align: center;
}

::v-deep .class {
  width: 6.25rem;
}

::v-deep .active_task_count {
  width: 9.375rem;
}

::v-deep .created_at {
  width: 12.5rem;
}

::v-deep .accept-btn {
  color: var(--a-color-white);
  background-color: var(--a-color-blue-dark);
  border-color: var(--a-color-blue-dark);
  &:hover {
    background-color: var(--a-color-secondary);
    color: var(--a-color-white);
    border-color: var(--a-color-secondary);
  }
}

.deny-btn {
  width: 136px;
  background-color: var(--a-color-primary);
  color: var(--a-color-white);
  border-color: var(--a-color-white);
}

button {
  &:hover {
    background-color: var(--a-color-secondary);
    color: var(--a-color-white);
    border-color: var(--a-color-secondary);
  }
}

.violet {
  color: var(--a-color-secondary);
}
</style>
